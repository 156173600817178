export default {
  translation: {
    app_title: "قندیل بلباس",
    home1: "بۆ بازرگانی گشتی سنوردار",
    home2: "بۆ بازرگانی گشتی سنوردار",
    home3: "بۆ بازرگانی گشتی سنوردار",
    home: "سەرەتا",
    about: "دەربارە",
    services: "خزمەتگوزارییەکانمان",
    partners: "هاوبەشەکان",
    sanaPanel: "سەنا پەنەڵ",
    powerPanel: "پاوەر پەنەڵ",
    power: "پاوەر",
    blackPipe: "بلاک پایپ",
    products: "بەرهەمەکانی ئێمە",
    contact: "پەیوەندیمان پێوەبکە",
    about_us: "دەربارەی ئێمە",
    about_p: `کۆمپانیای قنديل بلباس ،کۆمپانیایەکی خۆماڵیە و لە ساڵی 2010 دامەزراوە و لەبواری هاوردەکردنی ئاسن و بەرهەمەکانی و بەرهەمهێنانی سەندەويچ پەنەڵ و دابەشکردنی لە سەرجەم پارێزگاکانی وڵات کاردەکات.
    کۆمپانیاکەمان هەوڵدەدات ئەو بەرهەمانە هاوردەبکات کە بۆ وڵاتەکەمان پێویستە لە بواری نۆژەنکردنەوە و بنیاتنانەوە و دروستکردنی ژێرخانی ئابوری . بەرهەم هاوردەکراوەکانمان بەپێی ستانداری عيراقی و نێودەوڵەتی لە کارگەکانی دەرەوەی وڵات بەرهەمدەهێنرێن و بەپێی داواکاری کڕیاران و پێداویستی بازاڕی ناوەخۆ هاوردەیان دەکەین و بۆ سەرجەم پارێزگاکانی وڵات دابەشيان دەکەين.
    کۆمپانیاکەمان هەوڵدەدات کەوا پەرە بە بەرهەمهێنانی ناوەخۆ بدات و بەرهەمهێنان خۆماڵی بکرێت ، بۆ ئەو مەبەستەش هەنگاوی ناوە بە بەرهەمهێنانی سەندەويچ پەنەڵ لە شاری زاخۆ و بۆ داهاتوش کارگەی بەرهەمهێنانی پرۆفيل و بۆڕی لە شاری هەولێر دابمەزرێنێ .`,
    why_chose_us: "بۆچی ئێمە؟",
    reason1: "پارێزراوی",
    contact_form: "پەیوەندیمان پێوەبکە",
    name: "ناو",
    email: "ئیمەیڵ",
    message: "نامە",
    send_message: "ناردنی نامە",
    contact_p:
      "An Iraqi delivery company. Our goal is to give the best model for delivery companies by employing expertise and using the latest internationally approved technology.",
    address: "ناونیشان",
    address_value:
      "عێراق، هەولێر، شەقامی ڕوناکی، بەرامبەر نەخۆشخانەی چاوی وێست ئای، باڵەخانەی سەید طيب بەقال، نهۆمی حەفتەم",
    phone: "ژمارە مۆبایل",
    copy_right: "© مافی پارێزراوە بۆ کۆمپانیای قەندیل بلباس",
    page_not_found: "Page Not Found",
    is_required: "is required!",
    not_valid: "is not a valid email!",
    service1: "هێلی بڕینی پلێت",
    service1_p:
      "بۆ بڕینی پلێت بە درێژی بۆ هەموو جۆرەکانی پلێتی ڕەنگی و گەلفەنایزو ڕەشی سارد و گەرم.",
    service1_li1: "بەرینی پلێت : لە 40سم تا 150سم",
    service1_li2: "ئەستوری پلێت : لە 0.20ملم تا 2.00 ملم",
    service1_li3: "درێژی بڕین : لە 125سم تا 700سم",
    service2: "هێلی دروستکردنی کێربی",
    service2_p:
      "بۆ ئامادەکردنی کێربی بۆ داپۆشینی سەربانەکان و بنمیچی خانوو و کۆگا و گەراجەکان.",
    service2_li1: "بەرینی پلێتی بەکارهاتوو : 120سم ، 122سم ،125سم .",
    service2_li2: "بەرینی کێربی : 100سم تەواو.",
    service2_li3: "ەستوری پلێتی بەکارهاتوو : لە 0.20ملم تا 0.90 ملم",
    service2_li4: "درێژی کێربی : لە 125سم تا 1700سم",
    s_top_header1: "كارگەی سەنا پەنەڵ بۆ بەرهەمهێنانی سەندەویج پەنەڵ",
    s_about_p:
      "کارگەی سەنا پەنەڵ كارگەيەكي خۆماڵيه، لەساڵی 2017 دامەزراوه و له ساڵی 2018 دا سەندەویچ پەنەڵی بەرهەمهێنا به هەردوو جۆری دیواری و بنمیچی، بەرهەمی سەندەویچ پەنەڵی پاوەرپەنەڵ بە ئامێری ئۆتۆماتیکی و کوالێتی بەرز و ستاندەری جیهانی و بەپێی داواکاری بەرهەم دەهێنرێن .ئامانجی کارگەی پاوەر پەنەڵ پەرەپێدان  ئاوەدانکردنەوە و گەشەپێدانی وڵاتەکەمانە بە بەرهەمێکی خۆماڵی و ڕەخساندنی هەلی کاری زیاتر بۆ هاوڵاتیانە.",
    s_whatspanel: "سەندویچ پەنەڵ چییە؟",
    s_whatspanel_p:
      "سەندویچ پەنەڵ بەرهەمێکە کێشی سوکە و لە سێ چین پێکدێت، بەوەش کۆمەڵێک توانا و تایبەتمەندی پێ دەبەخشێ، بەوەی نەگەیەنەرێکی باشە بۆ گەرمی و ساردی و دەنگ و هەروەها ئاسانی بەستان و بەکارهێنانی. لە نێوان دوو چینی دژە ژەنگی پلێتی سەرەوە و خوارەوە چینێک لە ماددەی پۆلی یۆریثین و پۆلیسترین دادەنرێت، ئەو ماددەیە چەند تایبەتمەندییەکی فیزیایی ناوازەی هەیە وەک بەرهەڵستی ئاگر و ساردی و گەرمی، بەوەش بەهای تێچووی بەکارهێنانی وزە کەم دەکاتەوە.",
    s_availables: "پێوانەو ئەستووری پەنەڵی بەرهەمهاتوو",
    s_specifics: "تایبەتمەندییەکان",
    s_spec_width: "پانی",
    s_spec_length: "درێژی",
    s_spec_thikness: "ئەستووری",
    s_spec_density: "چڕی",
    s_spec_thermal: "گەیاندنی گەرمی",
    s_spec_sound: "تێپەڕینی دەنگ",
    s_roof: "پەنەڵی بنمیچ",
    s_wall: "پەنەڵی دیوار",
    s_f_1: "1000 مم",
    s_f_2: "18 م ماکس",
    s_f_3: "50, 75, 100 مم",
    s_f_4: "40 کگم/م",
    s_f_4a: " (∓2)",
    s_f_5: "0.022 w/mk",
    s_f_6: "25 dB",
    s_application: "بەکارهێنانەکانی",
    s_application_p:
      "سەندوچ پەنەڵ لە زۆر بواردا بەکاردێت بەتایبەت لە بنیاتنانی پێشانگا و کۆگا و خانوو و کۆگاکانی ساردکەرەوە و چێشتخانە و هۆڵی وەرزشی و کێڵگەی پەلەوەر و قوتابخانە و شوێنی بازرگانی و فرۆکەخانە و هتد",
    s_type: "جۆری بەرهەمی کارگە",
    s_type1: "سەندەویچی جۆری Rigid Polyurethane (PUR)",
    s_type2: "سەندەویچی جۆری Polyisocyanurate (PIR)",
    s_type3: "Sandwich Panel Ridges",
    s_specification: "تایبەتمەندییەکانی سەندویچ پەنەڵ",
    s_specific_1: "دژە باران و هەڵم",
    s_specific_2: "دژە ژەنگ",
    s_specific_3: "بەرگری دژی ڕەشەبا و زریان",
    s_specific_4: "بەرگری دژی تیشکی خۆر",
    s_specific_5: "بەرگری دژی ماددە کیمیاییەکان",
    s_specific_6: "دژە ئاگر",
    s_specific_7: "دژەگەرمی",
    s_specific_8: "دژە دەنگ",
    s_specific_9: "دژە زەمینلەرزە و لەرینەوە",
    s_specific_10: "دژە مێروو",
    s_specific_11: "جوانی لە ڕواڵەت و رێکی لە ئەندازە",
    s_specific_12: "دۆستی ژینگە",
    s_specific_13: "نرخی کەم و خێرا لە بنیاتنان",
    s_specific_14: "ڕەنگی جۆراوجۆر",
    s_specific_15: "توندی پتەوی",
    s_specific_16: "توانای دووبارە بەکارهێنانەوە",
    s_add_value: "دهوك ، زاخۆ ، حسن ئافا ، پشت پێشانگاکانی ئۆتۆمبیل",
    p_top_header1: "كارگەی پاوەر پەنەڵ بۆ بەرهەمهێنانی سەندەویج پەنەڵ",
    p_about_p:
      "کارگەی پاوەر پەنەڵ كارگەيەكي خۆماڵيه، لەساڵی 2017 دامەزراوه و له ساڵی 2018 دا سەندەویچ پەنەڵی بەرهەمهێنا به هەردوو جۆری دیواری و بنمیچی، بەرهەمی سەندەویچ پەنەڵی پاوەرپەنەڵ بە ئامێری ئۆتۆماتیکی و کوالێتی بەرز و ستاندەری جیهانی و بەپێی داواکاری بەرهەم دەهێنرێن .ئامانجی کارگەی پاوەر پەنەڵ پەرەپێدان  ئاوەدانکردنەوە و گەشەپێدانی وڵاتەکەمانە بە بەرهەمێکی خۆماڵی و ڕەخساندنی هەلی کاری زیاتر بۆ هاوڵاتیانە.",
    p_whatspanel: "سەندویچ پەنەڵ چییە؟",
    p_whatspanel_p:
      "سەندویچ پەنەڵ بەرهەمێکە کێشی سوکە و لە سێ چین پێکدێت، بەوەش کۆمەڵێک توانا و تایبەتمەندی پێ دەبەخشێ، بەوەی نەگەیەنەرێکی باشە بۆ گەرمی و ساردی و دەنگ و هەروەها ئاسانی بەستان و بەکارهێنانی. لە نێوان دوو چینی دژە ژەنگی پلێتی سەرەوە و خوارەوە چینێک لە ماددەی پۆلی یۆریثین و پۆلیسترین دادەنرێت، ئەو ماددەیە چەند تایبەتمەندییەکی فیزیایی ناوازەی هەیە وەک بەرهەڵستی ئاگر و ساردی و گەرمی، بەوەش بەهای تێچووی بەکارهێنانی وزە کەم دەکاتەوە.",
    p_availables: "پێوانەو ئەستووری پەنەڵی بەرهەمهاتوو",
    p_specifics: "تایبەتمەندییەکان",
    p_spec_width: "پانی",
    p_spec_length: "درێژی",
    p_spec_thikness: "ئەستووری",
    p_spec_density: "چڕی",
    p_spec_thermal: "گەیاندنی گەرمی",
    p_spec_sound: "تێپەڕینی دەنگ",
    p_roof: "پەنەڵی بنمیچ",
    p_wall: "پەنەڵی دیوار",
    p_f_1: "1000 مم",
    p_f_2: "18 م ماکس",
    p_f_3: "50, 75, 100 مم",
    p_f_4: "40 کگم/م",
    p_f_4a: " (∓2)",
    p_f_5: "0.022 w/mk",
    p_f_6: "25 dB",
    p_application: "بەکارهێنانەکانی",
    p_application_p:
      "سەندوچ پەنەڵ لە زۆر بواردا بەکاردێت بەتایبەت لە بنیاتنانی پێشانگا و کۆگا و خانوو و کۆگاکانی ساردکەرەوە و چێشتخانە و هۆڵی وەرزشی و کێڵگەی پەلەوەر و قوتابخانە و شوێنی بازرگانی و فرۆکەخانە و هتد",
    p_type: "جۆری بەرهەمی کارگە",
    p_type1: "سەندەویچی جۆری Rigid Polyurethane (PUR)",
    p_type2: "سەندەویچی جۆری Polyisocyanurate (PIR)",
    p_type3: "Sandwich Panel Ridges",
    p_specification: "تایبەتمەندییەکانی سەندویچ پەنەڵ",
    p_specific_1: "دژە باران و هەڵم",
    p_specific_2: "دژە ژەنگ",
    p_specific_3: "بەرگری دژی ڕەشەبا و زریان",
    p_specific_4: "بەرگری دژی تیشکی خۆر",
    p_specific_5: "بەرگری دژی ماددە کیمیاییەکان",
    p_specific_6: "دژە ئاگر",
    p_specific_7: "دژەگەرمی",
    p_specific_8: "دژە دەنگ",
    p_specific_9: "دژە زەمینلەرزە و لەرینەوە",
    p_specific_10: "دژە مێروو",
    p_specific_11: "جوانی لە ڕواڵەت و رێکی لە ئەندازە",
    p_specific_12: "دۆستی ژینگە",
    p_specific_13: "نرخی کەم و خێرا لە بنیاتنان",
    p_specific_14: "ڕەنگی جۆراوجۆر",
    p_specific_15: "توندی پتەوی",
    p_specific_16: "توانای دووبارە بەکارهێنانەوە",
    p_add_value: "دهوك ، زاخۆ ، حسن ئافا ، پشت پێشانگاکانی ئۆتۆمبیل",
  },
};
