export default {
  translation: {
    app_title: "قندیل بلباس",
    home1: "للتجارة العامة المحدودة",
    home2: "للتجارة العامة المحدودة",
    home3: "للتجارة العامة المحدودة",
    home: "الرئسية",
    about: "من نحن",
    services: "خدماتنا",
    partners: "شركاء",
    sanaPanel: "سنا بنل",
    powerPanel: "باور(القوة) بنل",
    power: "باور(القوة)",
    blackPipe: "بلاك بايب",
    products: "منتجاتنا",
    contact: "اتصل",
    about_us: "معلومات عنا",
    about_p: `شرکة قنديل بلباس شرکة محلية تأسست في سنة 2010 تعمل في مجال إستيراد و توزيع منتجات الحديد و سندويج بنل في البلاد . 
    ان شركتنا تقوم بإستيراد المواد و المنتجات اللازمة و الضرورية في تعمير و إنشاء بنى التحتية للبلاد وتنتج المواد وفق معايير و مقاييس و مواصفات عراقية ودولية وحسب طلبيات الزبائن و حاجات السوق و تتم توزيعها في کافة محافظات .
    تهدف شرکتنا الى إنتاج و تصنيع المواد داخل البلاد وذلك بإنشاء معامل و مصانع خاصة بإنتاج المواد وقد أقدمت على إنتاج سندويج بنل في زاخو وفي المستقبل نقوم بإنشاء معمل لانتاج مقاطع حديدية (بروفيل ) و انابيب الحديدية في أربيل .`,
    why_chose_us: "لماذا نحن ؟",
    reason1: "الامان",
    contact_form: "اتصل",
    name: "اسم",
    email: "البريد الإلكتروني",
    message: "رسالة",
    send_message: "إرسال رسالة",
    contact_p:
      "An Iraqi delivery company. Our goal is to give the best model for delivery companies by employing expertise and using the latest internationally approved technology.",
    address: "عنوان",
    address_value: `العراق، أربيل، شارع روناكي، مقابل مشفى ويست اي للعيون، عمارة سيد طيب البقال ،طابق السابع`,
    phone: "هاتف",
    copy_right: "© جميع الحقوق محفوظة لشريكة قندەل بلباس",
    page_not_found: "Page Not Found",
    is_required: "مطلوب!",
    not_valid: "ليس بريدًا إلكترونيًا صالحًا!",
    service1: "خط تقطيع ",
    service1_p:
      "لتقطيع رولات و تحويلها الى بنادل (ربطات) حسب طلبات ، و لکافة انواع الحديد المغلون ، الملون ، رول اسود بارد ، رول اسود حار.",
    service1_li1: "عرض الرول: من 40سم و لغاية 150سم",
    service1_li2: "سماكة الرول: من 0.20 ملم الى 2.00 ملم",
    service1_li3: "طول التقطيع : من 125سم الى 700 سم",
    service2: "خط الكيربي ( جملون السقف )",
    service2_p:
      "لانتاج الواح الکيربي (جملون السقف ) لسقوف الابنية و المخازن و الساحات.",
    service2_li1: "عرض الرول المستخدم : 120سم ، 122سم ، 125سم .",
    service2_li2: "عرض المنتج : 100سم (صافي )",
    service2_li3: "سماكة بليت : من 0.20 الى 0.90 ملم",
    service2_li4: "طول الکيربي : من 125سم الى 1700 سم",
    s_top_header1: "سنا بنل مصنع لإنتاج سندويج بنل",
    s_about_p:
      "معمل سنا بنل لصناعة سندويج بنل ، هی احدی معامل محلية انشأت في سنة 2017 وبدأ بانتاج سندويج بنل (الواح عازلة ) منذ سنة 2018 بنوعيه السقفي و الجداري ، منتوجات معمل القوة تصنع بمكائن أوتوماتيكية و بجودة عالية و وفق معايير عالمية وحسب طلبيات . تهدف معمل القوة إلى تنمية و تطوير بنية التحتية لبلدنا و تأمين مواد بجودة عالية و محلية الصنع و زيادة اتاحة فرص عمل للمواطنين",
    s_whatspanel: "ما هو سندويج بنل؟",
    s_whatspanel_p:
      "سندويج بنل منتوج خفيف يتكون من ثلاث طبقات تمنحه ميزات و قدرات فائقة, يتمتع سندويج بنل بققاومة كبير للحرارة و عزال جديد للصوت و خفة وزنه و سهولة تركيبه. تتوسط الطبقة العازلة المصنوعة من مادة بولي يوريثين و بوليسترين بين طبقتين علوية و السفلية مصنوعة من الواح مضادة للصدا , تتسم هذه المواد العازلة بخصوص فيزيائية مميزة كمقاومتها للحرارة و الاشتعال و البرودة مما يقلل من تكاليف استخدام الطاقة",
    s_availables: "القياسات وسمك الالواح المنتج",
    s_specifics: "المميزات",
    s_spec_width: "العرض",
    s_spec_length: "الطول",
    s_spec_thikness: "سماكة",
    s_spec_density: "كثافة",
    s_spec_thermal: "توصيل حراري",
    s_spec_sound: "عزل الصوت",
    s_roof: "لوحة السقف",
    s_wall: "لوحة الجدار",
    s_f_1: "1000 مم",
    s_f_2: "18 م الأعلى",
    s_f_3: "50, 75, 100 مم",
    s_f_4: "40 کغم/م",
    s_f_4a: " (∓2)",
    s_f_5: "0.022 w/mk",
    s_f_6: "25 dB",
    s_application: "التطبيقات",
    s_application_p:
      "يمكن استخدام الالواح العازلة بشكل واسع في مجال البناء و الانشاءات مثل انشاء, المعارض , المطاعم , المسابيح , المدارس , الاسواق التجارية الكبيرة , قاعات حقول الدواجن و قاعة الاندية الرياضية و قاعات الطارات",
    s_type: "منتجات المعمل",
    s_type1: "سندويج بنل نوعية Rigid Polyurethane (PUR)",
    s_type2: "سندويج بنل نوعية Polyisocyanurate (PIR)",
    s_type3: "Sandwich Panel Ridges",
    s_specification: "مواصفات سندويج بنل",
    s_specific_1: "مقاوم للامطار و الابخرة",
    s_specific_2: "مقاوم للصداء",
    s_specific_3: "مقاوم للرياح والعواصف ",
    s_specific_4: "عدم التاثر باشعة الشمس",
    s_specific_5: "مقاوم للمواد الكيمياوية ",
    s_specific_6: "مقاوم للاشتعال ",
    s_specific_7: "عازل لدرجات الحرارة",
    s_specific_8: "عازل للصوت",
    s_specific_9: "مقوم للصدمات والهزات الارضية",
    s_specific_10: "مقاوم للحشرات",
    s_specific_11: "يتميز بمظهر جميل",
    s_specific_12: "حماية من التلوث البيئي",
    s_specific_13: "كلفة قليلة و السرعة في الانشاء",
    s_specific_14: "متوفر بالوان متعددة ",
    s_specific_15: "متانة عالية",
    s_specific_16: "يمكن اعادة استخدامة",
    s_add_value: "دهوك ، زاخو ، حسن ئافا ، خلف معرض السيارات",
    p_top_header1: "باور بنل مصنع لإنتاج سندويج بنل",
    p_about_p:
      "معمل  باور(القوة) لصناعة سندويج بنل ، هی احدی معامل محلية انشأت في سنة 2017 وبدأ بانتاج سندويج بنل (الواح عازلة ) منذ سنة 2018 بنوعيه السقفي و الجداري ، منتوجات معمل القوة تصنع بمكائن أوتوماتيكية و بجودة عالية و وفق معايير عالمية وحسب طلبيات . تهدف معمل القوة إلى تنمية و تطوير بنية التحتية لبلدنا و تأمين مواد بجودة عالية و محلية الصنع و زيادة اتاحة فرص عمل للمواطنين",
    p_whatspanel: "ما هو سندويج بنل؟",
    p_whatspanel_p:
      "سندويج بنل منتوج خفيف يتكون من ثلاث طبقات تمنحه ميزات و قدرات فائقة, يتمتع سندويج بنل بققاومة كبير للحرارة و عزال جديد للصوت و خفة وزنه و سهولة تركيبه. تتوسط الطبقة العازلة المصنوعة من مادة بولي يوريثين و بوليسترين بين طبقتين علوية و السفلية مصنوعة من الواح مضادة للصدا , تتسم هذه المواد العازلة بخصوص فيزيائية مميزة كمقاومتها للحرارة و الاشتعال و البرودة مما يقلل من تكاليف استخدام الطاقة",
    p_availables: "القياسات وسمك الالواح المنتج",
    p_specifics: "المميزات",
    p_spec_width: "العرض",
    p_spec_length: "الطول",
    p_spec_thikness: "سماكة",
    p_spec_density: "كثافة",
    p_spec_thermal: "توصيل حراري",
    p_spec_sound: "عزل الصوت",
    p_roof: "لوحة السقف",
    p_wall: "لوحة الجدار",
    p_f_1: "1000 مم",
    p_f_2: "18 م الأعلى",
    p_f_3: "50, 75, 100 مم",
    p_f_4: "40 کغم/م",
    p_f_4a: " (∓2)",
    p_f_5: "0.022 w/mk",
    p_f_6: "25 dB",
    p_application: "التطبيقات",
    p_application_p:
      "يمكن استخدام الالواح العازلة بشكل واسع في مجال البناء و الانشاءات مثل انشاء, المعارض , المطاعم , المسابيح , المدارس , الاسواق التجارية الكبيرة , قاعات حقول الدواجن و قاعة الاندية الرياضية و قاعات الطارات",
    p_type: "منتجات المعمل",
    p_type1: "سندويج بنل نوعية Rigid Polyurethane (PUR)",
    p_type2: "سندويج بنل نوعية Polyisocyanurate (PIR)",
    p_type3: "Sandwich Panel Ridges",
    p_specification: "مواصفات سندويج بنل",
    p_specific_1: "مقاوم للامطار و الابخرة",
    p_specific_2: "مقاوم للصداء",
    p_specific_3: "مقاوم للرياح والعواصف ",
    p_specific_4: "عدم التاثر باشعة الشمس",
    p_specific_5: "مقاوم للمواد الكيمياوية ",
    p_specific_6: "مقاوم للاشتعال ",
    p_specific_7: "عازل لدرجات الحرارة",
    p_specific_8: "عازل للصوت",
    p_specific_9: "مقوم للصدمات والهزات الارضية",
    p_specific_10: "مقاوم للحشرات",
    p_specific_11: "يتميز بمظهر جميل",
    p_specific_12: "حماية من التلوث البيئي",
    p_specific_13: "كلفة قليلة و السرعة في الانشاء",
    p_specific_14: "متوفر بالوان متعددة ",
    p_specific_15: "High durability",
    p_specific_16: "Recyclable",
    p_add_value: "دهوك ، زاخو ، حسن ئافا ، خلف معرض السيارات",
  },
};
